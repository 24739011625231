import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ButtonDelete from '../utils/buttonDelete';
import { CardActionArea } from '@mui/material';
import ModalInput from '../utils/ModalInput.jsx';
import PriceInput from '../utils/priceInput.jsx';
import FullWidthTextField from '../utils/LargeTextField.jsx';


export default class PackCard extends React.Component {
    constructor(props) {
       super(props);
       this.getId = this.getId.bind(this);
       this.deletePack = this.deletePack.bind(this);
       this.updatePack = this.updatePack.bind(this);
    }

    getId(){
      this.props.handleClick(this.props.id);
    }

    deletePack(){
      this.props.deletePack(this.props.id);
    }

    updatePack(){
      this.props.updatePack(this.props.id);
    }

    render() {
        return (
          <div onClick={this.getId} id={this.props.id}>
            <Card sx={{ maxWidth: 300, height:500,boxShadow:15 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="400"
                image={this.props.src}
                alt={this.props.title}
              />
               <Typography  variant="h5" component="div" color="black">
                  {this.props.title}
                </Typography>
                <Typography  variant="h6" component="div" color="black">
                  Budget : {this.props.budget} €
                </Typography>
            </CardActionArea>
            <div style={{ display: 'flex',  alignItems: 'center' }}>
              <ButtonDelete id={this.props.id} onClick={this.deletePack}/>
              <ModalInput 
                buttonTitle="Update" 
                descriptionHeader="Modification du budget"
                buttonColor="outline-primary"
                modalBody={
                  <div>
                    Nouveau budget : {<PriceInput/>}
                    Titre de la Formule (optionnel) : {<FullWidthTextField/>}
                  </div>
                }
                callBackOnSubmit={this.updatePack}
                />
            </div>
          </Card>  
          </div>
        );
     }
}