import React, { Component } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

class NumberInputAdornments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      value: '',
    };
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({ value: value !== '' ? parseFloat(value) : '' });
  };

  render() {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <div>
          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <InputLabel htmlFor="filled-adornment-amount">Cost</InputLabel>
            <FilledInput
              id="filled-adornment-amount"
              startAdornment={<InputAdornment position="start">€</InputAdornment>}
              type="number"
              value={this.state.value}
              onChange={this.handleInputChange}
            />
          </FormControl>
        </div>
      </Box>
    );
  }
}

export default NumberInputAdornments;
