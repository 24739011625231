import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import { useNavigate } from 'react-router-dom';

import {User} from '../../model/user.model.ts';
import ModalInput from '../utils/ModalInput.jsx';
import {addUser,verifyUser} from '../../service/user.service.ts'; 
import FullWidthTextField from '../utils/LargeTextField.jsx';
import { useRef } from 'react';




interface FormElements extends HTMLFormControlsCollection {
  login: HTMLInputElement;
  password: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}



type GoToExpenseApFunction = (id: string) => void;




const login = async (formElements:FormElements,goToExpenseAp:GoToExpenseApFunction) => {
  const data : User = {
    login: formElements.login.value,
    password: formElements.password.value
  };

  
  const response = await verifyUser(data);

  if(response.ok){
    const loggedUserToken : string = await response.text();
    goToExpenseAp(loggedUserToken);
  }else {
    const error = await response.json();
    localStorage.removeItem('token');
    console.log(error);
  }
    
}

const register = async (formElements:FormElements,goToExpenseAp:GoToExpenseApFunction,registrationCode:string) => {
  const data : User = {
    login: formElements.login.value,
    password: formElements.password.value,
    budget:0,
    savings:0
  };

  const response = await addUser(data,registrationCode);

  if(response.ok){
    const registeredUserToken : string = await response.text();
    goToExpenseAp(registeredUserToken);
  }else {
    const error = await response.json();
    console.log(error);
  }
    
}


function FormBuilderAuthent(){
  const [isLogging, setIsLogging] = React.useState(false);
  const [registrationCode, setRegistrationCode] = React.useState("");
  
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  const navigate = useNavigate();

  const goToExpenseAp = (id : string) => {
    const url = `/expenseApp`;
    localStorage.setItem('token', id);
    navigate(`${url}`);
  };

  const handleFormSubmit = () => {
    if (formRef.current) {
      //déclenche l'évenement submit -> le onsubmit est appelé
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };
  

  


  return (<Stack gap={4} sx={{ mt: 2 }}>
    <form 
    ref={formRef}
    onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                        event.preventDefault();
                        const formElements = event.currentTarget.elements;
                        if(isLogging){
                          login(formElements,goToExpenseAp);
                        }else{
                          if(inputRef.current)
                            register(formElements,goToExpenseAp,inputRef.current.value);
                        }
                      }
                  }
    >
      <FormControl required>
        <FormLabel>Login</FormLabel>
        <Input type="text" name="login" />
      </FormControl>
      <FormControl required>
        <FormLabel>Password</FormLabel>
        <Input type="password" name="password" />
      </FormControl>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* <Checkbox size="sm" label="Remember me" name="persistent" /> */}
          {/* <Link level="title-sm" href="#replace-with-a-link">
            Forgot your password?
          </Link> */}
        </Box>
        
        <Button type='submit' onClick={()=>setIsLogging(true)} fullWidth>
          Sign in
        </Button>

        <ModalInput
          buttonTitle="Sign up" 
          descriptionHeader="Enter the registration code"
          buttonColor="primary"
          modalBody={<input type="text" placeholder="code" ref={inputRef} id="modalSignInId" style={{backgroundColor : 'lightGray',borderBlockColor : 'lightGray', borderRadius: 5}} /> }
          callBackOnClick = {()=> setIsLogging(false)}
          callBackOnSubmit={() => {
              if(inputRef.current){
                handleFormSubmit();
              }
          } }
        />

      </Stack>
    </form>
  </Stack>);
}



function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="neutral"
      aria-label="toggle light/dark mode"
      {...other}
      onClick={(event) => {
        if (mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
        onClick?.(event);
      }}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}



export default function JoySignInSideTemplate() {
  
  localStorage.removeItem('token');
  
  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              
              <Typography level="title-lg">Welcome Dude</Typography>
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              {/* <Stack gap={1}>
                <Typography level="h3">Sign in</Typography>
                <Typography level="body-sm">
                  New to myIT?{' '}
                  <Link href="#replace-with-a-link" level="title-sm">
                    Sign up!
                  </Link>
                </Typography>
              </Stack> */}
              {/* <Button
                variant="soft"
                color="neutral"
                fullWidth
                startDecorator={<GoogleIcon />}
              >
                Continue with Google
              </Button> */}
            </Stack>
            {/* <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector('light')]: {
                  color: { xs: '#FFF', md: 'text.tertiary' },
                  '--Divider-lineColor': {
                    xs: '#FFF',
                    md: 'var(--joy-palette-divider)',
                  },
                },
              })}
            >
              or
            </Divider> */}
            <FormBuilderAuthent/>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Ehuy Jean Emmanuel {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
        })}
      />
    </CssVarsProvider>
  );
}
