import List from '../utils/list';
import React from 'react';
import {Container} from 'reactstrap';
import PriceInput from '../utils/priceInput';
import ButtonDelete from '../utils/buttonDelete';
import ButtonAdd from '../utils/buttonAdd';
import LargeTextField from '../utils/LargeTextField';
import Slider from '../utils/Slider';
import { baseUrl } from '../../environment/env.ts';


export default class expectation extends React.Component {
   constructor(props) {
      super(props);
      this.state = {display_list : null};
      this.addExpectation = this.addExpectation.bind(this);
      this.deleteExpectation = this.deleteExpectation.bind(this);
   }

   componentDidMount(){
      this.getListOfexpectations();
   }

   componentDidUpdate(prevProps) {
      if (prevProps.expectations !== this.props.expectations) {
         this.getListOfexpectations();
      }
   }

   getListOfexpectations(){
      const expectations = this.props.expectations;
      const columns = this.initColumns();
      const rows = this.initRows(expectations);
      this.setState({expectations : expectations,
         display_list : <List
                           rows={rows}
                           columns={columns}
                        /> 
   });
   }

   initColumns(){
      const columns = [
         { id: 'name', label: 'Name', minWidth: 170,
         format : v => v },
         { id: 'previsionSpending', label: 'Amount', minWidth: 50,
          format : v => v},
         { id: 'previsionExpected', label: 'Expected', minWidth: 100,
         format : v => v },
         { id: 'action', label: 'Action', minWidth: 50,
          format : val => (val.desc === "ajouter") ? <ButtonAdd onClick={this.addExpectation}/> : <ButtonDelete id={val.id} onClick={this.deleteExpectation}/> 
          },
       ];
      return columns;
   }

   initRows(data){
      const createSection = this.createexpectationData();
      const rows = data.map(expectation => this.createData(expectation.id,expectation.name,<Slider maxValue={expectation.previsionExpected} defaultValue={expectation.previsionSpending}/>,expectation.previsionExpected,{desc : "delete", id : expectation.id}));
      const res = [createSection,...rows];
      return res;
   }

   createData(id,name,previsionSpending,previsionExpected,action) {
      return {id,name,previsionSpending,previsionExpected,action};
   }

    createexpectationData(){
      const id = "createexpectation";
      const name = <LargeTextField/>;
      const previsionSpending = "";
      const previsionExpected = <PriceInput/>;
      const action = {desc : "ajouter", id : id};
      return {id,name,previsionSpending,previsionExpected,action};
   }

   async addExpectation(){
      let cost = document.getElementById('filled-adornment-amount').value;
      cost = (cost === '') ? (0.0) : parseFloat(cost);

      const expectationObject = {
         name : document.getElementById('fullWidth').value,
         previsionExpected : cost,
         previsionSpending : 0.0
      };

      const body = JSON.stringify(expectationObject);
      const requestOptions = {
         method : 'POST',
         headers : {...this.props.authorization.headers, "Content-Type": "application/json" },
         body : body
      };
      
      const response = await fetch(`${baseUrl}/expectation`,requestOptions);
      const expectation = await response.json();
     
      this.update();
   }

    async deleteExpectation(event){
      const id = event.target.id;
      await fetch(`${baseUrl}/expectation/${id}`, {...this.props.authorization,method:'DELETE'});
      this.update();
    }

   update(){
      this.props.update("expectation");
      this.getListOfexpectations();
   }

   render() {
      return (
         <div className='app'>
            <div>
               <Container fluid>
               <h3>List of all your expectations</h3>
               {this.state.display_list}
               </Container>
            </div>
         </div>

      );
   }
}
