import React, { Component } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

class GroupOrientation extends Component {
  render() {
    const buttons = [
      <Button key="create" id='create' onClick={this.props.onClick}>Create</Button>,
      <Button key="choose" id='choose' onClick={this.props.onClick}>Choose</Button>,
    ];

    return (
      <Box
        sx={{
          display: 'flex',
          '& > *': {
            m: 1,
          },
        }}
      >
        <ButtonGroup
          variant="contained"
        >
          {buttons}
        </ButtonGroup>
      </Box>
    );
  }
}

export default GroupOrientation;
