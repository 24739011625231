import React from 'react';
import { videos } from '../../scripts/videos.js';
import Carousel from 'react-bootstrap/Carousel';
import Video from './video.jsx';
import HomeBar from '../utils/homebar.jsx';
import Typography from '@mui/material/Typography';
import { baseUrl } from '../../environment/env.ts';
import { getAuthorizedHeader } from '../../scripts/lib.ts';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/pianoGallery.css'; // Import custom styles for the carousel

export default class PianoGallery extends React.Component {
   constructor(props) {
      super(props);
      this.state = {authorizedHeader : null};
   }

//    componentDidMount() {
//       this.setAuthorization(); 
//   }
  
//   setAuthorization() {
//       this.setState({ authorizedHeader : getAuthorizedHeader()},
//           () => { 
//             this.verifyAuthent();
//           }
//       );
//   }
//      async verifyAuthent(){
//       const response = await fetch(`${baseUrl}/user`,{...this.state.authorizedHeader,method: 'GET'});
//       const user = await response.json();
//       if(user == null){
//          alert("redirection");
//       }
//     }

   displayVideos(category, nbToDisplay) {
      const filtered = videos.filter(video => video.category === category);
      return this.createCarousel(filtered, nbToDisplay);              
   }

   createCarousel(videos, nbToDisplay) {
      const n = videos.length;
      let start = 0;
      const res = [];
      while (start + nbToDisplay < n) {
         res.push(this.createCarouselItem(videos, start, start + nbToDisplay));
         start += nbToDisplay;
      }
      if (start + nbToDisplay >= n) res.push(this.createCarouselItem(videos, start, start + nbToDisplay));
      return res;
   }

   createCarouselItem(videos, start, end) {
      return (
         <Carousel.Item key={start}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
               {videos.slice(start, end).map(video => <Video key={video.src} src={video.src} title={video.title} />)}
            </div>
            <br /> <br />
         </Carousel.Item>
    );
   }

   render() {
      // Get unique categories
      const categories = [...new Set(videos.map(video => video.category))];

      return (
         <div className='piano_gallery'>
            <HomeBar />
            <div className='gallery-header'>
               <Typography variant="h1" component="div" className="gallery-title">
                  Maestro Maishima
               </Typography>
            </div>

            {/* Display carousel for each category */}
            {categories.map(category => (
               <div key={category} className="category-section">
                  <Typography variant="h2" className="category-title">
                     {category}
                  </Typography>
                  <Carousel
                     indicators={true}
                     controls={true}
                     prevIcon={<span className="carousel-control-prev-icon" />}
                     nextIcon={<span className="carousel-control-next-icon" />}
                     nextLabel=""
                     prevLabel=""
                     className="custom-carousel"
                     interval={null} // Disable auto slide
                     pause="hover" // Optional: pause the carousel on hover
                  >
                     {this.displayVideos(category, 4)}
                  </Carousel>
               </div>
            ))}
            {/* End of carousel for each category */}
         </div>
      );
   }
}
