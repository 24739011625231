import List from '../utils/list';
import React from 'react';
import {Container} from 'reactstrap';
import PriceInput from '../utils/priceInput';
import ButtonDelete from '../utils/buttonDelete';
import ButtonAdd from '../utils/buttonAdd';
import LargeTextField from '../utils/LargeTextField';
import MoneyOffIcon from '../utils/moneyOffIcon';
import MoneyOnIcon from '../utils/moneyOnIcon'; 
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { baseUrl } from '../../environment/env.ts';


export default class Debit extends React.Component {
   constructor(props) {
      super(props);
      this.state = {debits : [], display_list : null};
      this.addDebit = this.addDebit.bind(this);
      this.deleteDebit = this.deleteDebit.bind(this);
      this.updateDebit = this.updateDebit.bind(this);
   }

   componentDidMount(){
      this.getListOfDebits();
   }

   componentDidUpdate(prevProps) {
      if (prevProps.debits !== this.props.debits) {
         this.getListOfDebits();
      }
   }
  

    getListOfDebits(){
      const debits = this.props.debits;
      const columns = this.initColumns();
      const rows = this.initRows(debits);
      this.setState({debits : debits,
         display_list : <List
                           rows={rows}
                           columns={columns}
                        /> 
   });
   }

   

   initColumns(){
      const columns = [
         { id: 'description', label: 'Description', minWidth: 170,
         format : v => v },
         { id: 'cost', label: 'Cost', minWidth: 100,
         format : v => v },
         { id: 'debitStatus', label: 'Status of Debit', minWidth: 50,
          format : val => this.formatDebitStatus(val)
         },
         { id: 'action', label: 'Action', minWidth: 50,
          format : val => (val.desc === "ajouter") ? <ButtonAdd onClick={this.addDebit}/> : <ButtonDelete id={val.id} onClick={this.deleteDebit}/> 
          },
       ];
      return columns;
   }

   formatDebitStatus(val){
      this.setState({prevDebitState : val.isDebited});
      if(val.action === "create")
            return <MonetizationOnIcon/>;
      if(!val.isDebited){
         return <MoneyOnIcon id={val.id} updateDebit={this.updateDebit}/>;
      }else{
         return <MoneyOffIcon id={val.id} updateDebit={this.updateDebit}/>;
      }
   }

   initRows(data){
      const createSection = this.createDebitData();
      const rows = data.map(debit => this.createData(debit.id,debit.description,debit.cost,debit,{desc : "delete", id : debit.id}));
      const res = [createSection,...rows];
      return res;
   }

   createData(id,description, cost, debitStatus,action) {
      return {id,description, cost, debitStatus,action};
   }

   createDebitData(){
      const id = "createDebit";
      const description = <LargeTextField/>;
      const cost = <PriceInput/>;
      const debitStatus = {isDebited:false,action:"create"};
      const action = {desc : "ajouter", id : id};
      return {id,description, cost, debitStatus,action};
   }

   async addDebit(){
      let cost = document.getElementById('filled-adornment-amount').value;
      cost = (cost === '') ? (0.0) : parseFloat(cost);

      const debitObject = {
         description : document.getElementById('fullWidth').value,
         cost : cost,
         isDebited : false
      };
      const body = JSON.stringify(debitObject);
      const requestOptions = {
         method : 'POST',
         headers : {...this.props.authorization.headers, "Content-Type": "application/json" },
         body : body
      };
      const response = await fetch(`${baseUrl}/debit`,requestOptions);
      this.update();
      
   }

   async getDebit(id){
      const requestOptions = {method : 'GET', headers : {...this.props.authorization.headers} };
      const response = await fetch(`${baseUrl}/debit/${id}`,requestOptions);
      return response;
   }

   async updateDebit(id){
      const requestOptions = {
         method : 'PUT', headers : {...this.props.authorization.headers}
      };
      const response = await fetch(`${baseUrl}/debit/${id}`,requestOptions);
      this.update();
   }

    async deleteDebit(event){
      const id = event.target.id;
      const response = await fetch(`${baseUrl}/debit/${id}`, {...this.props.authorization,method:'DELETE'});
      this.update();
    }

   update(){
      this.props.update('debit');
      this.getListOfDebits();
   }

   render() {
      return (
         <div className='app'>
            <div>
               <Container fluid>
               <h3>List of all your debits</h3>
               {this.state.display_list}
               </Container>
            </div>
         </div>

      );
   }
}
