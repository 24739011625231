import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}€`;
}

class DiscreteSliderMarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: this.props.defaultValue,
    };
  }

  handleSliderChange = (event, newValue) => {
    // Empêcher le changement de valeur
    this.setState({ sliderValue: this.props.defaultValue });
  };

  render() {
    const { sliderValue } = this.state;
    const { maxValue } = this.props;

    const numMarks = 4; // Nombre fixe de marques que vous souhaitez afficher

    // Calculer les valeurs des marques en fonction du nombre d'intervalles
    const interval = Math.ceil(maxValue / (numMarks - 1));
    const marks = Array.from({ length: numMarks }, (_, i) => i * interval);
    return (
      <Box sx={{ width: 250 }}>
        <Slider
          aria-label="Custom marks"
          value={sliderValue}
          onChange={this.handleSliderChange}
          getAriaValueText={valuetext}
          step={interval}
          valueLabelDisplay="auto"
          marks={marks.map(value => ({ value, label: `${value}€` }))}
          max={maxValue} // Définir la valeur maximale du Slider
        />
      </Box>
    );
  }
}

export default DiscreteSliderMarks;
