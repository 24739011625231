
import HomeBar from '../utils/homebar';
import ButtonPackEnv from '../utils/buttonPackEnv';
import React from 'react';
import PackCreate from './pack_create';
import PackChoose from './pack_choose';
import { baseUrl } from '../../environment/env.ts';
import { getAuthorizedHeader } from '../../scripts/lib.ts';



export default class ExpensePack extends React.Component {
   constructor(props) {
      super(props);
      this.state = {list : null,user:null};
      this.handleClickEnv = this.handleClickEnv.bind(this);
      this.update = this.update.bind(this);
   }

   componentDidMount() {
      this.setAuthorization(); 
  }
  
  setAuthorization() {
      this.setState({ authorizedHeader : getAuthorizedHeader()},
          () => { 
            this.initCallServices();
          }
      );
  }

  async initCallServices(){
     await this.getCurrentUser();
     this.getListOfPack();
 }

 async getCurrentUser(){
   const response = await fetch(`${baseUrl}/user`,{...this.state.authorizedHeader,method: 'GET'});
   const user = await response.json();
   this.setState({user:user});
 }

 async getListOfPack(){
   const response = await fetch(`${baseUrl}/packs`,{...this.state.authorizedHeader,method:'GET'});
   const packs = await response.json();
   this.setState({list : <PackChoose packs={packs} 
                                     update={this.update}
                                     authorization={this.state.authorizedHeader}/>});
 }

handleClickEnv(event){
   if(event.target.id === "create")
         this.setState({list : <PackCreate authorization={this.state.authorizedHeader}/>});
   if(event.target.id === "choose")
         this.getListOfPack();
}

update(alias){
  if(alias === "pack")
    this.getListOfPack();
}


   render() {
    return (
      <div className='app'>
        <HomeBar/>
        <ButtonPackEnv onClick={this.handleClickEnv}/>
        {this.state.list}
       </div>
    );
  }
} 