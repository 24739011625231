import List from '../utils/list';
import React from 'react';
import {Container} from 'reactstrap';
import PriceInput from '../utils/priceInput';
import ButtonDelete from '../utils/buttonDelete';
import ButtonAdd from '../utils/buttonAdd';
import LargeTextField from '../utils/LargeTextField';
import Select from '../utils/Select';
import { baseUrl } from '../../environment/env.ts';


export default class Expense extends React.Component {
   constructor(props) {
      super(props);
      this.state = {display_list : null,ids_select : null};
      this.addExpense = this.addExpense.bind(this);
      this.deleteExpense = this.deleteExpense.bind(this);
      this.handleSetIdsSelect = this.handleSetIdsSelect.bind(this);
   }


   componentDidMount(){
      this.getListOfExpenses();
   }

   componentDidUpdate(prevProps) {
      if (prevProps.expenses !== this.props.expenses) {
         this.getListOfExpenses();
      }
   }

   getListOfExpenses(){
      const expenses = this.props.expenses;
      const columns = this.initColumns();
      const rows = this.initRows(expenses);
      this.setState({expenses : expenses,
         display_list : <List
                           rows={rows}
                           columns={columns}
                        /> 
   });
   }

   initColumns(){
      const columns = [
         { id: 'description', label: 'Description', minWidth: 170,
         format : v => v },
         { id: 'cost', label: 'Cost', minWidth: 100,
         format : v => v },
         { id: 'category', label: 'Category', minWidth: 50,
          format : v => v},
         { id: 'action', label: 'Action', minWidth: 50,
          format : val => (val.desc === "ajouter") ? <ButtonAdd onClick={this.addExpense}/> : <ButtonDelete id={val.id} onClick={this.deleteExpense}/> 
          },
       ];
      return columns;
   }

   initRows(data){
      const createSection = this.createExpenseData();
      const rows = data.map(expense => this.createData(expense.id,expense.description,expense.price,expense.category,{desc : "delete", id : `${expense.id}&${expense.category}`}));
      const res = [createSection,...rows];
      return res;
   }

   createData(id,description,cost,category,action) {
      return {id,description,cost,category,action};
   }

    createExpenseData(){
      const id = "createExpense&create";
      const description = <LargeTextField/>;
      const cost = <PriceInput/>;
      const action = {desc : "ajouter", id : id};
      const category = <Select key={"select"} handleSetIdsSelect={this.handleSetIdsSelect} data={this.props.expectations}/>;
      return {id,description,cost,category,action};
   }

   handleSetIdsSelect(ids){
      this.setState({ids_select : ids});
   }

   async addExpense(){
      let cost = document.getElementById('filled-adornment-amount').value;
      cost = (cost === '') ? (0.0) : parseFloat(cost);

      const expenseObject = {
         description : document.getElementById('fullWidth').value,
         price : cost,
         category : "test"
      };

      const body = JSON.stringify(expenseObject);
      const requestOptions = {
         method : 'POST',
         headers : {...this.props.authorization.headers, "Content-Type": "application/json" },
         body : body
      };
      
      const id = this.state.ids_select.value;

      if(id === null || id === '' || !id){
         alert("Veuillez sélectionner une Catégory");
      }else{
         const response = await fetch(`${baseUrl}/expectation/${id}/expense`,requestOptions);
         const expense = await response.json();
      }
      this.update();
   }

    async deleteExpense(event){
      const id = event.target.id.split('&')[0];
      await fetch(`${baseUrl}/expense/${id}`, {...this.props.authorization,method:'DELETE'});
      this.update();
    }

   update(){
      this.props.update('expense');
      this.getListOfExpenses();
   }

   render() {
      return (
         <div className='app'>
            <div>
               <Container fluid>
               <h3>List of all your expenses</h3>
               {this.state.display_list}
               </Container>
            </div>
         </div>

      );
   }
}
