import React, { Component } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

class SelectAutoWidth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_id: '',
      category_name:''
    };
  }

  handleChange = (event) => {
    this.props.handleSetIdsSelect(event.target);
    this.setState({ category_id: event.target.value, category_name: event.target.name });
  };

  render() {
    return (
      <div>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="create_select"
            value={this.state.category_id}
            name={this.state.category_name}
            onChange={this.handleChange}
            autoWidth
            label="category"
          >
                {this.props.data.map(dataObject => <MenuItem value={dataObject.id} name={dataObject.name} key={dataObject.id}>{dataObject.name}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default SelectAutoWidth;
