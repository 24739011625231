import React, { Component } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

class MultilineTextFields extends Component {
  constructor(props) {
    super(props);
    // Vous pouvez traiter les props ici si nécessaire
  }

  render() {
    return (
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '50%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={this.props.id}
            label={this.props.label}
            multiline
            rows={4}
            placeholder={`<Nom d'un des ${this.props.label}> : <Coût en €>`}
            variant="filled"
          />
        </div>
        
      </Box>
    );
  }
}

export default MultilineTextFields;
