import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default class InfoExpectation extends React.Component {
    constructor(props) {
       super(props);
    }

    createLineInfo(){
      const res = ``;
      return res;
    }

    getReachedExpectationDistance(expectationObject){
      return Math.min(0,(expectationObject.previsionExpected - expectationObject.previsionSpending))
    }

    getCurrentPossibleSavings(savingsExpected,expectationObjects){
      return savingsExpected + (expectationObjects.reduce((prev,elm) => prev+this.getReachedExpectationDistance(elm),0));
    }

    displayPossibleSavings(savingsExpected,expectationObjects){
      const possibleSavings = this.getCurrentPossibleSavings(savingsExpected,expectationObjects);
      if(possibleSavings != savingsExpected)
        return <Typography variant="body2" color="text.secondary" fontSize={25}>
            {`Tu peux encore économiser ${possibleSavings} € ! Courage !`}
                </Typography>;
      else
        return "";
    }

    displayAllReachedExpectation(expectationObjects){
      const objects = expectationObjects.filter(elm => this.getReachedExpectationDistance(elm) < 0);
      if(objects.length == 0)
          return "Toutes les prévisions sont respectés. Bien joué !";
      else {
          return objects.map(elm =>
            <Typography key={elm.id} variant="body2" color="text.secondary" fontSize={25}>
                  {`Dépassement de ${this.getReachedExpectationDistance(elm)} € dans la section ${elm.name}.`}
            </Typography>
            );
      }
    }

    render() {
        const expectations = this.props.expectations;
        return (
            <Card sx={{  boxShadow: 15, width : '100%' }}>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  Info : 
                </Typography>
                <Typography variant="body2" color="text.secondary" fontSize={25}>
                  {this.displayAllReachedExpectation(expectations)}
                </Typography>
                  {this.displayPossibleSavings(this.props.savings,expectations)}
                <Typography variant="body2" color="text.secondary" fontSize={25}>
                  {`Total prévisionnel : ${
                    expectations.reduce((prev,elm) => prev+elm.previsionSpending,0)
                  }/${
                    expectations.reduce((prev,elm) => prev+elm.previsionExpected,0)
                  } €`}
                </Typography>
              </CardContent>

            </CardActionArea>
          </Card>  
        );
     }
}
