import React from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

export default class VideoInfo extends React.Component {
    constructor(props) {
       super(props);
    }

    render() {
        return (
            <CardContent 
            sx={{ 
               maxWidth: 350,
               maxHeight: 100
               }}
               
               >
                  <Typography gutterBottom variant="h5" component="div" color="darkcyan">
                     Details
                  </Typography>
                  <Typography variant="body2" color="whitesmoke">
                     Title : {this.props.title}
                  </Typography>
                  {/* <Typography variant="body2" color="whitesmoke">
                     views (remplacer par une icone) : 0
                  </Typography> */}
               </CardContent>
          );
     }
}