import React, { Component } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

class GroupOrientation extends Component {
  render() {
    const buttons = [
      <Button key="debit" id='debit' onClick={this.props.onClick}>Prélèvement</Button>,
      <Button key="expectation" id='expectation' onClick={this.props.onClick}>Prévision</Button>,
      <Button key="expense" id='expense' onClick={this.props.onClick}>Dépense</Button>,
    ];

    return (
      <Box
        sx={{
          display: 'flex',
          '& > *': {
            m: 1,
          },
        }}
      >
        <ButtonGroup
          variant="contained"
        >
          {buttons}
        </ButtonGroup>
      </Box>
    );
  }
}

export default GroupOrientation;
