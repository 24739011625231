import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

class IconLabelButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial state properties if needed
    };
  }

  render() {
    return (
      <Stack direction="row" spacing={2}>
        <Button
          onClick={this.props.onClick}
          variant="contained"
          sx={{ backgroundColor: 'brown' }}
          endIcon={<SendIcon />}
        >
          Send Pack
        </Button>
      </Stack>
    );
  }
}

export default IconLabelButtons;
