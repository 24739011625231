import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default class Savings extends React.Component {
    constructor(props) {
       super(props);
    }


    render() {
        return (
            <Card sx={{ maxWidth: 345, boxShadow: 15,width:'100%' }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image="/images/terrasse.jpg"
                alt="terrasse"
              />
               <Typography  variant="h1" component="div" color="black">
                  {this.props.user.savings}
                </Typography>

              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Savings
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  "{`${this.props.user.login}`} Juste imagine toi, t'es trop un crack !!!!!!"
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>  
        );
     }
}
