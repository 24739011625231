import React from 'react';
import VideoInfo from './videoinfo.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default class Video extends React.Component {
    constructor(props) {
       super(props);
    }

    render() {
        return (
            <Card 
                className='video' 
                sx={{
                   maxWidth: 300, // Augmente la largeur de la carte légèrement
                   maxHeight: 480, // Augmente la hauteur totale de la carte légèrement
                   backgroundColor: 'black',
                   borderRadius: '15px', // Coins arrondis
                   boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Ombre légère
                   transition: '0.3s', // Transition fluide pour l'effet de survol
                   '&:hover': {
                       boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.3)', // Ombre plus profonde au survol
                       transform: 'scale(1.05)', // Légère augmentation de la taille au survol
                   }
                }}
            >
               <video 
                  width="300" // Largeur de la vidéo augmentée
                  height="170" // Hauteur de la vidéo augmentée
                  controls
                  style={{ 
                      objectFit: 'cover', 
                      borderRadius: '15px 15px 0 0', // Coins arrondis supérieurs pour la vidéo
                      display: 'block' // Assure un affichage en bloc
                  }}
               >
                   <source src={this.props.src} type="video/mp4"/>
               </video>
               <CardContent 
                   sx={{ 
                      padding: '8px', // Réduit le padding pour un meilleur alignement
                      backgroundColor: '#1e1e1e', // Fond plus sombre pour le contenu
                      borderRadius: '0 0 15px 15px', // Coins arrondis inférieurs
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      height: '130px', // Hauteur ajustée pour le bloc d'information
                      width: '100%', // Assure que le bloc d'information occupe toute la largeur de la carte
                   }}
               >
                   <VideoInfo 
                       title={this.props.title} 
                       sx={{ 
                           fontSize: '0.875rem', // Taille de la police ajustée
                           margin: 0, // Élimine les marges par défaut
                       }} 
                   />
               </CardContent>
            </Card>     
        );
     }
}
