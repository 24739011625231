export const videos = [
    {
        title : "No Game no Life (opening)",
        src : "/videos/noGameNoLife.mp4",
        category : "Anime"
    },
    {
        title : "Oshi No Ko (opening : Idol)",
        src : "/videos/oshinoko.mp4",
        category : "Anime"
    },
    {
        title : "Takt op (opening)",
        src : "/videos/taktop.mp4",
        category : "Anime"
    },
    {
        title : "Black Clover (opening : Black Catcher)",
        src : "/videos/blackclover.mp4",
        category : "Anime"
    },
    {
        title : "Your Lie in April (opening 2)",
        src : "/videos/your_lie.mp4",
        category : "Anime"
    },
    {
        title : "Demon Slayer (opening : Gurenge)",
        src : "/videos/gurenge.mp4",
        category : "Anime"
    },
    {
        title : "Your Lie in April (training session : Orange)",
        src : "/videos/orange_training.mp4",
        category : "Anime"
    },
    {
        title : "River Flows in you",
        src : "/videos/riverflows.mp4",
        category : "Melody"
    },
    {
        title : "Your Lie in April (melody)",
        src : "/videos/your_lie_melody.mp4",
        category : "Melody"
    },
    {
        title : "determinate",
        src : "/videos/determinate.mp4",
        category : "Melody"
    },
    {
        title : "melody2",
        src : "/videos/melody2.mp4",
        category : "Melody"
    },
    {
        title : "melody3",
        src : "/videos/old_melody.mp4",
        category : "Melody"
    },
    {
        title : "melody4",
        src : "/videos/impro_test1.mp4",
        category : "Melody"
    },
    {
        title : "My first Melody",
        src : "/videos/first_melody.mp4",
        category : "Melody"
    },
    {
        title : "My second Melody",
        src : "/videos/second_melody.mp4",
        category : "Melody"
    },
    {
        title : "Car il est glorieux (part 2)",
        src : "/videos/melody1.mp4",
        category : "Gospel"
    },
    {
        title : "Même dans le tombeau",
        src : "/videos/tombeau.mp4",
        category : "Gospel"
    },
    {
        title : "Il est parmis nous",
        src : "/videos/parmi_nous.mp4",
        category : "Gospel"
    },
    {
        title : "Mon âme béni le seigneur",
        src : "/videos/ame_beni.mp4",
        category : "Gospel"
    },
]

