import React, { Component } from 'react';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

class MoneyOffICON extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.getId = this.getId.bind(this);
  }

  getId(){
    this.props.updateDebit(this.props.id);
  }

  render() {
    return (
      <div>
        <MoneyOffIcon id={this.props.id} onClick={this.getId}/>
      </div>
    );
  }
}

export default MoneyOffICON;