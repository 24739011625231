import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


export default class List extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
        page : 0,
        rowsPerPage : 10
    };
      
   }

   setPage = (pages) => {
    this.setState({ page: pages });
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage: rowsPerPage });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setRowsPerPage(+event.target.value);
    this.setPage(0);
  };


   render() {
      const columns = this.props.columns;
      const rows = this.props.rows;
      const page = this.state.page;
      const rowsPerPage = this.state.rowsPerPage;

      return (
        <Paper sx={{ width: '100%', overflow: 'hidden',maxWidth : 900 }}>
        <TableContainer sx={{ maxHeight: 300, width : 900 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow"+row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={"TableCell"+row.id+column.id} align={column.align}>
                            {column.format(value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
      );
   }
}