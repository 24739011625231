import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PianoGallery from './piano_section/PianoGallery';
import ExpenseApp from './expense_section/ExpenseApp';
import ExpensePack from './expense_section/ExpensePack';
import SignIn from './sign-in-side/SignIn.tsx';


export default class App extends React.Component {
   constructor(props) {
      super(props);
   }

//    componentDidMount(){
//       this.setAuthorization();
//    }

//    setAuthorization() {
//       const username = 'jean1';
//       const password = 'jean1';
//       const encodedCredentials = btoa(`${username}:${password}`);
//       this.setState({ authorizedHeader: { 
//          headers: { 
//             'Authorization': `Basic ${encodedCredentials}` 
//          } 
//       } 
//    });
//   }

   


   render() {

      return (
         <Router>
            <Routes>
               <Route path="/" element={<SignIn/>} />
               <Route path="sign" element={<SignIn/>}/>
               <Route path="expenseApp" element={<ExpenseApp/>} />
               <Route path="expenseApp/pack" element={<ExpensePack/>} />
               <Route path="pianoGallery" element={<PianoGallery/>} />
            </Routes>
         </Router>
      );
   }
}