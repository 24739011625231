import React, { Component } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

class FullWidthTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial state properties if needed
    };
  }

  render() {
    return (
      <Box
        sx={{
          width: 250,
          maxWidth: '100%',
        }}
      >
        <TextField fullWidth label="description" id="fullWidth" />
      </Box>
    );
  }
}

export default FullWidthTextField;
